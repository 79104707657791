import { InputNumberProps, InputNumber as inputNumber } from 'antd';
import styled, { css } from 'styled-components';

interface IMainInputNumberProps extends InputNumberProps {
  color_type?: string;
  mb?: number;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  cursor?: string;
  radius?: string;
  shadow?: string;
  text_align?: string;
  hide?: string;
  border_color?: string;
  arrows?: string;
  arrow_color?: string;
  isDark?: boolean;
  color?: string;
  arrows_hide?: boolean;
}

const InputNumber = styled(inputNumber)<IMainInputNumberProps>`
  box-shadow: ${props =>
    props.isDark ? '0px 4px 4px 0px #FFFFFF40 inset' : '0 4px 8px 0 #060c2933 inset'};
  border-radius: 2px;
  font-size: 14px;
  background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
  border: 0.2px solid #060c2940;
  color: ${props => (props.isDark ? '#fff' : '#000')};
  &:hover {
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
  }
  .ant-input-number-focused {
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
  }
  &.ant-input-number {
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')} !important;
  }
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.arrows === 'visible' &&
    css`
      & .ant-input-number-handler-wrap {
        opacity: 1;
      }
    `}
  ${props =>
    props.arrow_color &&
    css`
      & .ant-input-number-handler-wrap span {
        color: ${props.arrow_color}!important;
      }
    `}
  ${props =>
    props.text_align &&
    css`
      input {
        text-align: ${props.text_align};
      }
    `}
  ${props =>
    props.border_color &&
    css`
      border: 1px solid ${props.border_color}!important;
    `}
    ${props =>
    props.color &&
    css`
      .ant-input-number-input {
        color: ${props.color};
      }
    `}
    ${props =>
    props.arrows_hide &&
    css`
      & .ant-input-number-handler-wrap {
        display: none;
      }
    `}
  &.ant-input-number-disabled {
    color: ${props => (props.isDark ? '#fff' : '#000')} !important;
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')} !important;
  }
`;

export default InputNumber;

import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import styled, { css } from 'styled-components';

interface IMainTextAreaProps extends TextAreaProps {
  type?: string;
  mb?: number;
  min_height?: string;
  max_height?: string;
  margin?: string;
  shadow?: string;
  padding?: string;
  height?: string;
  cursor?: string;
  radius?: string;
  border?: string;
  isDark?: boolean;
  color?: string;
  height_body?: string;
}

const TextArea = styled(Input.TextArea)<IMainTextAreaProps>`
  box-shadow: ${props =>
    props.isDark ? '0px 4px 4px 0px #FFFFFF40 inset' : '0 4px 8px 0 #060c2933 inset'};
  background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')} !important;
  border: 0.2px solid #060c2940;

  ${props =>
    props.height_body &&
    css`
      &.ant-input {
        height: ${props.height_body} !important;
      }
    `}

  &:disabled {
    color: ${props => (props.isDark ? '#fff' : '#000')} !important;
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')} !important;
  }

  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080;
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
    ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height};
    `}
    ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
    ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.height &&
    css`
      height: ${props.height} !important;
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `};
`;

export default TextArea;

import { TableProps, Table as table } from 'antd';
import styled, { css } from 'styled-components';

interface IMainTableProps extends TableProps<any> {
  width?: string;
  cols_width?: string;
  mb?: string;
  table_border?: string;
  table_interaction_color?: string;
  back_color?: string;
  shadow?: string;
  row?: string;
  head_color?: string;
  header_back_color?: string;
  footer_back_color?: string;
  expand_back?: string;
  footer_top_border?: string;
  footer_padding?: string;
  cell_padding?: string;
  body_color?: string;
  body_fw?: string;
  body_border?: string;
  body_border_color?: string;
  head_border_color?: string;
  body_br?: string;
  type?: string;
  hide?: string;
  extra?: string;
  secType?: string;
  pagination_margin?: string;
  pagination_padding?: string;
  rowHeight?: number;
  flex?: string;
  odd_row_color?: string;
  double_exp_row_color?: string;
  expand_pos?: string;
  append_cell_back?: string;
  expanded_row_extras_display?: string;
  head_fw?: string;
  head_fz?: string;
  body_fz?: string;
  x_overflow?: string;
  y_overflow?: string;
  height?: number;
  isDark?: boolean;
}

const Table = styled(table)<IMainTableProps>`
  /* && tbody > tr:hover > td {
    background: ${props => (props.isDark ? props.theme.dark_blue : '#fff')} !important;
  }
  .ant-empty-description {
    color: ${props => (props.isDark ? '#fff' : '#000')};
  } */
  .ant-table-cell {
    background-color: ${props => (props.isDark ? '#031338' : '#fff')};
  }
  .ant-table-row > .ant-table-cell-row-hover {
    background: ${props => (props.isDark ? '#031338' : '#fff')} !important;
  }
  .ant-table-thead tr {
    th {
      background-color: ${props => (props.isDark ? '#031338' : '#fff')};
      color: ${props => (props.isDark ? '#fff' : '#000')};
      border: 1px solid #e9e9e9;
      padding: 5px 10px;
      white-space: nowrap;

      &::before {
        display: none !important;
      }
    }
    th + th {
      border-left: none;
    }
  }

  .ant-table-tbody tr {
    td {
      padding: 5px 10px;
      border: 1px solid #e9e9e9;
    }
    td + td {
      border-left: none;
    }
  }

  .ant-table-expanded-row > .ant-table-cell {
    padding: 5px 10px 10px;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.cols_width &&
    css`
      th {
        width: ${props.cols_width};
      }
    `}
  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${props =>
    props.row &&
    css`
      .ant-table-cell {
        width: ${props.row};
      }
    `}
  ${props =>
    props.head_color &&
    css`
      .ant-table-thead > tr > th {
        color: ${props.head_color};
      }
    `}
  ${props =>
    props.header_back_color &&
    css`
      .ant-table-thead > tr > th {
        background-color: ${props.header_back_color};
      }
    `}
  ${props =>
    props.footer_back_color &&
    css`
      .ant-table-footer {
        background-color: ${props.footer_back_color};
      }
    `}
  ${props =>
    props.expand_back &&
    css`
      .ant-table-expanded-row .ant-table-cell {
        background-color: ${props.expand_back};
      }
    `}
  ${props =>
    props.footer_top_border &&
    css`
      .ant-table-footer {
        border-top: ${props.footer_top_border} !important;
      }
    `}
  ${props =>
    props.footer_padding &&
    css`
      .ant-table-footer {
        padding: ${props.footer_padding} !important;
      }
    `}
  ${props =>
    props.cell_padding &&
    css`
      .ant-table-cell {
        padding: ${props.cell_padding} !important;
      }
    `}
  ${props =>
    props.body_color &&
    css`
      .ant-table-tbody > tr td {
        color: ${props.body_color};
      }
    `}
  ${props =>
    props.body_fw &&
    css`
      .ant-table-tbody > tr td {
        font-weight: ${props.body_fw};
      }
    `}
  ${props =>
    props.body_border &&
    css`
      .ant-table-tbody > tr td {
        border: ${props.body_border};
      }
    `}
  ${props =>
    props.table_border &&
    css`
      table {
        border: ${props.table_border};
      }
    `}
  ${props =>
    props.table_interaction_color &&
    css`
      &:hover tr td {
        background-color: ${props.table_interaction_color}!important;
      }
    `}
  ${props =>
    props.back_color &&
    css`
      & tr td {
        background-color: ${props.back_color}!important;
      }
    `}
  ${props =>
    props.shadow &&
    css`
      & table {
        box-shadow: ${props.shadow}!important;
      }
    `}
  ${props =>
    props.head_border_color &&
    css`
      .ant-table-thead tr th {
        border-color: ${props.head_border_color};
      }
    `}
  ${props =>
    props.body_border_color &&
    css`
      .ant-table-tbody > tr td {
        border-color: ${props.body_border_color};
      }
    `}
  ${props =>
    props.body_br &&
    css`
      .ant-table-tbody > tr td {
        border-right: ${props.body_br};
      }
    `}
  ${props =>
    props.body_fz &&
    css`
      .ant-table-tbody > tr td {
        font-size: ${props.body_fz};
      }
    `}
    ${props =>
    props.type === 'supplies' &&
    css`
      .ant-table-thead tr th {
        border: 0.2px solid #4a5173;
        background: ${props.isDark ? '#383D54' : '#ededed'};
        border-bottom: none !important;
        text-align: center !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        padding: 3px 5px !important;
        color: ${props.isDark ? '#fff' : props.theme.main_color};
      }
      .ant-table-tbody tr td {
        border-color: #9d9da7 !important;
        padding: 3px 10px !important;
        background: ${props.isDark ? props.theme.dark_blue : '#fff'};
        border-left: 1px solid #4a5173 !important;
        border-right: 1px solid #4a5173 !important;
        border-bottom: 1px solid #4a5173 !important;
        border-top: 1px solid ${props.isDark ? 'transparent' : '#4a5173'} !important;
      }

      .ant-table-tbody tr:hover td {
        background: ${props.isDark ? props.theme.dark_blue : '#f5f5f5'} !important;
      }

      .ant-empty-description {
        color: ${props.isDark ? '#fff' : '#000'} !important;
      }
    `}
  ${props =>
    props.type === 'main-table' &&
    css`
      & .ant-table-cell {
        padding: 10px 5px !important;
      }
      & tr .ant-table-cell {
        background: white !important;
      }
      & .ant-table-row .ant-table-cell + .ant-table-cell {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          width: 1px;
          height: 40px;
          background: #e9ecef;
        }
      }
      & .ant-table-thead > tr > th {
        font-weight: 400 !important;
        text-transform: uppercase;
      }
      & thead .ant-table table {
        font-size: 14px !important;
        line-height: 0 !important;
      }
    `}
  ${props =>
    props.hide === 'body' &&
    css`
      .ant-table-tbody {
        display: none;
      }

      .ant-table,
      .ant-table-container {
        border-bottom: none;
      }

      .ant-table-thead > tr > th {
        border-bottom: none;
      }
    `}
  ${props =>
    props.extra === 'pagination' &&
    css`
      .ant-pagination-total-text {
        margin-right: auto;
      }
      .ant-pagination-item-ellipsis,
      .ant-pagination-item,
      .ant-pagination-jump-next-custom-icon {
        background-color: white !important;
        border-radius: 50%;
        border: none !important;
        a,
        .svg {
          color: #ff9900;
        }

        &:hover,
        &.ant-pagination-item-active {
          background-color: #ff9900 !important;

          a,
          svg {
            color: #fff !important;
          }
        }
      }

      .ant-pagination-item-ellipsis,
      .ant-pagination-item-link-icon {
        color: #ff9900 !important;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        background-color: white;
        border-radius: 50%;

        &.ant-pagination-disabled,
        &.ant-pagination-disabled:hover {
          & svg {
            color: rgba(0, 0, 0, 0.25);
          }
          border-color: #d9d9d9;
          background-color: transparent;
        }

        &:hover {
          background-color: #ff9900;
          border: none !important;
          & svg {
            color: #fff;
          }
        }
      }
    `}
  ${props =>
    props.secType === 'credit-card' &&
    css`
      & .ant-table-container table > thead > tr:first-child th:first-child {
        border-bottom: none !important;
        &::before {
          display: none;
        }
      }
      & .ant-table-container table > tbody > tr td:first-child {
        border-bottom: none !important;
      }
      & .ant-table-container table > tbody > tr:last-child td:first-child {
        border-bottom: 1px solid #e9ecef !important;
      }
      & .ant-table-container table > thead > tr th::before {
        width: 2px !important;
      }
      & .ant-table-measure-row {
        display: none;
      }

      tr .ant-table-cell {
        font-weight: 400 !important;
        font-size: 14px !important;
        white-space: pre;
      }
      .ant-table-cell {
        padding: 10px !important;
      }
    `}
  ${props =>
    props.pagination_margin &&
    css`
      .ant-pagination {
        margin: ${props.pagination_margin};
      }
    `}
  ${props =>
    props.pagination_padding &&
    css`
      .ant-pagination {
        padding: ${props.pagination_padding};
      }
    `}
  ${props =>
    props.rowHeight &&
    css`
      .ant-table-thead > tr {
        height: ${props.rowHeight}px;
      }
    `}
  ${props =>
    props.flex === 'contents' &&
    css`
      .ant-table-thead > tr {
        display: contents;
      }
    `}
  ${props =>
    props.odd_row_color &&
    css`
      .ant-table-tbody .ant-table-row {
        &:nth-child(odd) {
          background-color: ${props.odd_row_color};
        }
      }
    `}

  ${props =>
    props.double_exp_row_color &&
    css`
      .ant-table-tbody {
        .ant-table-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            background: #fff;
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            background: ${props.double_exp_row_color};
          }
        }
        .ant-table-expanded-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            td {
              background: ${props.double_exp_row_color};
            }
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            td {
              background: #fff;
            }
          }
        }
      }
    `}
  ${props =>
    props.flex === 'contents-none' &&
    css`
      .ant-table-thead > tr > .ant-table-cell {
        padding: 11px 16px;
      }
    `}
  ${props =>
    props.expand_pos &&
    css`
      .ant-table-row-expand-icon {
        float: ${props.expand_pos};
      }
    `}
  ${props =>
    props.append_cell_back &&
    css`
      .ant-table-row-level-0 > .ant-table-cell-with-append {
        background-color: ${props.append_cell_back};
      }
    `}
  ${props =>
    props.expanded_row_extras_display &&
    css`
      .ant-table-row-level-1 > .ant-table-cell-with-append {
        .ant-table-row-expand-icon,
        .ant-table-row-indent {
          display: ${props.expanded_row_extras_display};
        }
      }
    `}
  ${props =>
    props.head_fz &&
    css`
      .ant-table-thead > tr > th {
        font-size: ${props.head_fz};
      }
    `}
  ${props =>
    props.head_fw &&
    css`
      .ant-table-thead > tr > th {
        font-weight: ${props.head_fw};
      }
    `}
  ${props =>
    props.x_overflow &&
    css`
      .ant-table-body {
        overflow-x: ${props.x_overflow};
      }
    `}
  ${props =>
    props.y_overflow &&
    css`
      .ant-table-body {
        overflow-y: ${props.y_overflow}!important;
      }
    `}
  ${props =>
    props.height &&
    css`
      .ant-table-body {
        height: ${props.height}px;
      }
    `}
`;

export default Table;

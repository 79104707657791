import { getTheme, getUserRole } from 'app/services/selector-helpers';
import DashboardHeader from 'components/molecules/dashboard-header';
import { IDashboard } from 'interfaces/global-interfaces';
import { FC, memo, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../app/store';
import DarkBackground from '../../assets/images/dark_background1.jpg';

const Wrapper = styled.div`
  padding: 15px 48px;

  @media screen and (max-width: 992px) {
    padding: 0 10px;
  }
`;

const DashboardWrapper: FC<IDashboard> = ({ routeData, children }) => {
  const role = useAppSelector(getUserRole);
  const isDark = useAppSelector(getTheme);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  return (
    <div
      style={
        isDark
          ? {
              backgroundImage: `url(${DarkBackground})`,
              minHeight: '100vh',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : { background: '#f4f7fe', minHeight: '100vh' }
      }
    >
      {role && (
        <DashboardHeader
          role={role}
          dropdownVisible={dropdownVisible}
          toggleDropdown={toggleDropdown}
        />
      )}
      <Wrapper onClick={() => setDropdownVisible(false)}>{children}</Wrapper>
    </div>
  );
};

export default memo(DashboardWrapper);
